import {
  Checkbox,
  createStyles,
  Group,
  Paper,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';
import { ResourceEditDialog } from './ResourceEditDialog';
import { ResourceDeleteDialog } from './ResourceDeleteDialog';
import { ResourceCreateDialog } from './ResourceCreateDialog';
import React, { useContext, useState } from 'react';
import IResource from '../../models/IResource';
import { ResourceContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import { RESOURCE_TYPE_ENUM_VALUES } from '../../api_enums/RESOURCE_TYPE_ENUM';
import { useTranslation } from 'react-i18next';
import IPackageUnit from '../../models/IPackageUnit';
import { VOLUME_UNIT_ENUM_VALUES } from '../../api_enums/VOLUME_UNIT_ENUM';
import cx from 'clsx';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    transition: 'box-shadow 150ms ease',
    after: {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: 'rem(1px) solid',
    },
  },
  scrolled: {
    boxShadow: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05)',
  },
}));

function createRows(elements: IResource[]) {
  return elements.map((element) => {
    return (
      <tr key={element?.id}>
        <td> {element?.name} </td>
        <td> {RESOURCE_TYPE_ENUM_VALUES.get(element?.type)?.label} </td>
        <td> {(element?.package_unit as IPackageUnit)?.name} </td>
        <td>
          {' '}
          {element?.weight} {element?.weight_unit}{' '}
        </td>
        <td>
          {' '}
          {element?.volume}{' '}
          {VOLUME_UNIT_ENUM_VALUES.get(element?.volume_unit).label}
        </td>
        <td>
          <Checkbox checked={element?.is_active} disabled />
        </td>
        <td>
          <Group spacing="xs">
            <ResourceEditDialog resource={element} />
            <ResourceDeleteDialog resource={element} />
          </Group>
        </td>
      </tr>
    );
  });
}

function ResourceTable() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const ctx = useContext(ResourceContext) as AbstractContextType<IResource>;
  const [scrolled, setScrolled] = useState(false);

  return (
    <Paper withBorder radius="md" p="xs">
      <Stack>
        <Title order={2}>Ressourcen</Title>
        <Group>
          <ResourceCreateDialog />
        </Group>
        <ScrollArea
          h={'85vh'}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <Table striped verticalSpacing="xs" fontSize="md">
            <thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              <tr>
                <th>{t('ResourceTable.Name')}</th>
                <th>{t('ResourceTable.Type')}</th>
                <th>{t('ResourceTable.PackingUnit')}</th>
                <th>{t('ResourceTable.Weight')}</th>
                <th>{t('ResourceTable.Volume')}</th>
                <th>{t('ResourceTable.Active')}</th>
                <th>{t('ResourceTable.Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {createRows(ctx.entities)}

              {ctx.loading &&
                [1, 2].map((id) => {
                  return (
                    <tr key={id}>
                      <td>
                        <Skeleton height={12} mt={6} width="50%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="70%" radius="xl" />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </ScrollArea>

        {!ctx.loading && ctx.entities.length == 0 && (
          <Text align="center">{t('ResourceTable.NoResourceAvailable')}</Text>
        )}
      </Stack>
    </Paper>
  );
}

export default ResourceTable;
