import {
  Avatar,
  Checkbox,
  createStyles,
  Group,
  Paper,
  ScrollArea,
  Skeleton,
  Table,
  Text,
  Tooltip,
} from '@mantine/core';
import { useContext, useState } from 'react';
import IUser from '../../models/IUser';
import { UserEditDialog } from './UserEditDialog';
import { UserDeleteDialog } from './UserDeleteDialog';
import { UserCreateDialog } from './UserCreateDialog';
import { UserRestoreDialog } from './UserRestoreDialog';
import ISection from '../../models/ISection';
import { UserContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import { RoleElement } from '../entityelements/RoleElement';
import { ROLE_ENUM_VALUES } from '../../api_enums/ROLE_ENUM';
import { useTranslation } from 'react-i18next';
import { RADIO_CALL_PREFIX_ENUM_VALUES } from '../../api_enums/RADIO_CALL_PREFIX_ENUM';
import cx from 'clsx';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    transition: 'box-shadow 150ms ease',
    after: {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: 'rem(1px) solid',
    },
  },
  scrolled: {
    boxShadow: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05)',
  },
}));

function createRows(elements: IUser[]) {
  return elements.map((element) => {
    let roleCounter = element.roles.length;
    return (
      <tr key={element.id}>
        <td> {element.name} </td>
        <td> {(element.assigned_to as ISection)?.name} </td>
        <td>
          {' '}
          {
            RADIO_CALL_PREFIX_ENUM_VALUES.get(element.radio_call_name_prefix)
              ?.label
          }{' '}
          {element.radio_call_name}{' '}
        </td>
        <td>
          {' '}
          {
            RADIO_CALL_PREFIX_ENUM_VALUES.get(element.radio_call_group_prefix)
              ?.label
          }{' '}
          {element.radio_call_group}{' '}
        </td>
        <td> {element.phone} </td>
        <td>
          {' '}
          {element.roles.length > 0 && (
            <Tooltip.Group openDelay={100}>
              <Avatar.Group spacing="sm">
                {element.roles.slice(0, 8).map((r: string) => (
                  <Tooltip key={r} label={<RoleElement value={r} />} withArrow>
                    <Avatar color={ROLE_ENUM_VALUES.get(r)?.color} radius="xl">
                      {ROLE_ENUM_VALUES.get(r)?.icon}
                    </Avatar>
                  </Tooltip>
                ))}
                {element.roles.length > 8 && (
                  <Tooltip
                    withArrow
                    position="bottom"
                    label={
                      <>
                        {' '}
                        {element.roles.slice(8).map((r) => (
                          <div key={r}>
                            <RoleElement value={r} />
                          </div>
                        ))}{' '}
                      </>
                    }
                  >
                    <Avatar radius="xl">+{element.roles.length - 8}</Avatar>
                  </Tooltip>
                )}
              </Avatar.Group>
            </Tooltip.Group>
          )}{' '}
        </td>
        <td>
          <Checkbox checked={element.is_active} disabled />
        </td>
        <td>
          <Group spacing="xs">
            <UserEditDialog user={element} />
            <UserRestoreDialog user={element} />
            <UserDeleteDialog user={element} />
          </Group>
        </td>
      </tr>
    );
  });
}

export function UserTable() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const ctx = useContext(UserContext) as AbstractContextType<IUser>;
  const [scrolled, setScrolled] = useState(false);

  return (
    <Paper withBorder radius="md" p="xs">
      <UserCreateDialog />
      <ScrollArea
        h={'85vh'}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table striped verticalSpacing="xs" fontSize="md">
          <thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <tr>
              <th>{t('UserTable.Username')}</th>
              <th>{t('UserTable.MissionSection')}</th>
              <th>{t('UserTable.RadioCallName')}</th>
              <th>{t('UserTable.RadioCallGroup')}</th>
              <th>{t('UserTable.PhoneNumber')}</th>
              <th>{t('UserTable.Roles')}</th>
              <th>{t('UserTable.Active')}</th>
              <th>{t('UserTable.Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {createRows(ctx.entities)}

            {ctx.loading &&
              [1, 2].map((id) => {
                return (
                  <tr key={id}>
                    <td>
                      <Skeleton height={12} mt={6} width="50%" radius="xl" />
                    </td>
                    <td>
                      <Skeleton height={12} mt={6} width="70%" radius="xl" />
                    </td>
                    <td>
                      <Skeleton height={12} mt={6} width="60%" radius="xl" />
                    </td>
                    <td>
                      <Skeleton height={12} mt={6} width="70%" radius="xl" />
                    </td>
                    <td>
                      <Skeleton height={12} mt={6} width="20%" radius="xl" />
                    </td>
                    <td>
                      <Skeleton height={12} mt={6} width="60%" radius="xl" />
                    </td>
                    <td>
                      <Skeleton height={12} mt={6} width="20%" radius="xl" />
                    </td>
                    <td>
                      <Skeleton height={12} mt={6} width="60%" radius="xl" />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </ScrollArea>
      {!ctx.loading && ctx.entities.length == 0 && (
        <Text align="center">{t('NoUsersAvailable.NoUsersAvailable')}</Text>
      )}
    </Paper>
  );
}
