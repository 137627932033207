import React, { useContext } from 'react';
import { createStyles, Grid } from '@mantine/core';

import { useTranslation } from 'react-i18next';
import StatsVerticalBarChart from './StatsVerticalBarChart';
import {
  ResourceContext,
  StatisticContext,
} from '../../context/ContextWrapper';
import { AuthContext, AuthContextType } from '../../context/AuthProvider';
import { AbstractContextType } from '../../context/AbstractProvider';
import IResource from '../../models/IResource';

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    display: 'flex',
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  stat: {
    minWidth: 98,
    minHeight: 140,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  selectorText: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 1,
    textAlign: 'center',
    marginBottom: 5,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  month: {
    fontSize: theme.fontSizes.sm,
    lineHeight: 1,
    textAlign: 'center',
  },

  controls: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing.xl * 2,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 0,
      marginBottom: theme.spacing.xl,
    },
  },

  date: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  control: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'calc(var(--mantine-spacing-xl) * 2)',
    height: 28,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.radius.md,
    transition: 'background-color 50ms ease',

    [theme.fn.smallerThan('xs')]: {
      height: 34,
      width: 34,
    },

    '&:hover': {
      backgroundColor: theme.colors[theme.primaryColor][5],
      color: theme.white,
    },
  },

  controlIcon: {
    [theme.fn.smallerThan('xs')]: {
      transform: 'rotate(-90deg)',
    },
  },

  icon: {
    justifyContent: 'center',
    marginTop: 'var(--mantine-spacing-lg)',
    color: theme.colors.blue[6],
  },

  label: {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 2,
  },

  value: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    fontSize: 22,
    lineHeight: 1,
  },
}));

function StatsControls() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const ctxStatistic = useContext(StatisticContext);
  const resourceCtx = useContext(
    ResourceContext,
  ) as AbstractContextType<IResource>;

  const { getOptions, setOptions } = useContext(AuthContext) as AuthContextType;
  const options = getOptions();

  const dataBarchart = {
    labels: ctxStatistic.entities[0]?.resources_interval.name_list,
    datasets: [
      {
        label: t('StatsControlsBarchart.OpenResources'),
        data: ctxStatistic.entities[0]?.resources_interval.open_count_list,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: 1,
      },
      {
        label: t('StatsControlsBarchart.DeliveredResources'),
        data: ctxStatistic.entities[0]?.resources_interval.delivered_count_list,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgb(54, 162, 235)',
        borderWidth: 1,
      },
    ],
  };

  const dataResourceIndicator = {
    labels: ctxStatistic.entities[0]?.resources_indicator.name_list,
    datasets: [
      {
        label: t('StatsControlsBarchart.OpenResources'),
        data: ctxStatistic.entities[0]?.resources_indicator.open_count_list,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgb(153, 102, 255)',
        borderWidth: 1,
      },
      {
        label: t('StatsControlsBarchart.DeliveredResources'),
        data: ctxStatistic.entities[0]?.resources_indicator
          .delivered_count_list,
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgb(255, 159, 64)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <Grid style={{ paddingBottom: '1rem' }}>
      <Grid.Col md={6} lg={6}>
        <StatsVerticalBarChart
          title={
            t('StatsControlsBarchart.Resources') +
            ' (' +
            t('StatsControlsBarchart.Total') +
            ': ' +
            (ctxStatistic.entities[0]?.resource_inquiry[0]?.sum_total || 0) +
            ')'
          }
          data={dataResourceIndicator}
          stacked={false}
        />
      </Grid.Col>
      <Grid.Col md={6} lg={6}>
        <StatsVerticalBarChart
          title={
            t('StatsControlsBarchart.RequestedResources') +
            ' (' +
            (options.indicator_timespan == 1
              ? t('StatsControlsBarchart.LastHour')
              : t('StatsControlsBarchart.TimeInterval', {
                  name: options.indicator_timespan.toString(),
                })) +
            ')'
          }
          data={dataBarchart}
          stacked={true}
        />
      </Grid.Col>
    </Grid>
  );
}

export default StatsControls;
